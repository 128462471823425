body {
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
}

.nav {
  position: sticky;
  top: 0;
  z-index: 101;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.39,0,.02,.99);
}

.nav-backdrop {
  background: linear-gradient(180deg,#000c0e -40%,#00071500);
  height: 120px;
  pointer-events: none;
  position: absolute;
  transition-property: opacity;
  width: 100%;
  opacity: 1;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.39,0,.02,.99);
}

.nav-bar {
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex: 0 0 auto;
  height: 64px;
  max-width: 100%;
  padding: 0 16px;
  position: relative;
  background: linear-gradient(180deg,#000c0e -40%,#00071500);
}

@media screen and (min-width: 1024px){
  .nav-bar {
    padding: 0 32px;
  }
}

.nav-logo {
  font-size: 0;
  height: 64px;
  vertical-align: middle;
  align-items: center;
  display: flex;
}

.nav-logo-title {
  position: absolute;
  top: -100px;
  left: -300px;
}

.nav-logo-wrapper {
  align-items: center;
  border: none;
  display: flex;
  height: 45px;
  position: relative;
}

.nav-logo-img {
  height: 30px;
  width: auto;
}

.nav-bar>.nav-items>a {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px;
  padding: 8px 16px;
  border-radius: 16px;
  position: relative;
  /* opacity: 0.9; */
  transition: all .45s;
}

.nav-bar>.nav-items>a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.mobile-nav-items {
  display: none;
}

.mobile-nav-icon {
  padding: 4px 8px;
  border-radius: 8px;
  transition: all .45s;
}

.mobile-nav-icon:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

@media screen and (max-width: 900px) {
  .nav-items {
    display: none;
  }

  .mobile-nav-items {
    display: inline-block;
  }
}

.mobile-nav-items {
  float: right;
  position: relative;
}

.mobile-nav-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 16px;
  right: 0;
  z-index: 1;
  box-shadow: 0 2px 4px #0003;
}

.mobile-nav-menu.show {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.mobile-nav-menu > a {
  color: #000;
  padding: 12px 32px;
  white-space: nowrap;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all .45s;
}

.mobile-nav-menu > a:first-child {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.mobile-nav-menu > a:last-child {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.mobile-nav-menu > a:hover,
.mobile-nav-menu > a:focus,
.mobile-nav-menu > a:active {
  background-color: #e0e3eb;
}

/* .nav-bar>.nav-items>a::before {
  content:"  ";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #fff;
  transition: all .45s;
}

.nav-bar>.nav-items>a:hover::before {
  width: 100%;
} */


#root {
  margin-top: -64px;
}

.main-container {
  background-color: #fff;
}

.dark-container {
  background-color: #000;
  color: #fff;
}

.step-container-one {
  background-image: linear-gradient(180deg, #8e2de2, #4a00e0);
}

.step-container-two {
  background-image: linear-gradient(180deg, #4a00e0, #0f0c29);
}

.step-container-three {
  background-image: linear-gradient(180deg, #0f0c29,#30cfd0);
}

.content-container {
  width: 100%;
}

.step-container {
  display: grid;
  place-items: center;
  align-content: center;
  min-height: 100vh;
  padding: 0 20px;
}

.main-content {
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 20px;
  text-align: center;
  overflow: hidden;
}

.dark-container .main-content {
  color: #fff;
}

.dark-container a {
  color: #fff;
}

.dark-container a:hover,
.dark-container a:focus,
.dark-container a:active {
  color: #999;
}

.main-container a {
  color: #000;
}

.main-container a:hover,
.main-container a:focus,
.main-container a:active {
  color: #999;
}

.policy-content-container {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
}

.contactus-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  align-items: stretch;
}

.contactus-title-container {
  width: 100%;
}

.contactus-column {
  width: 50%;
}

.contactus-content-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactus-content:not(:first-child) {
  margin-top: 30px;
}

.contactus-subtitle {
  margin-top: 0;
}

.contactus-hours-list {
  list-style-type: none;
}

@media screen and (max-width: 900px) {
  .contactus-column {
    width: 100%;
  }
}

.map-responsive{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}

.map-responsive iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}

.step-title-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.step-content {
  color: #fff;
  display: grid;
  grid-template-areas:
        "header"
        "content";
  row-gap: 4px;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 0 32px;
  }
  .main-content {
    padding: 150px 32px;
  }
  .step-title-content {
    padding: 40px 32px;
  }
  .step-content {
    grid-template-areas:
        "header"
        "content"
        "button";
    row-gap: 12px;
  }
}

@media screen and (min-width: 1280px) {
  .step-content {
    gap: 20px 48px;
    grid-template-areas:
        "header content"
        "button content";
    grid-template-columns: 1fr 2fr;
    width: 1200px;
  }
}

@media screen and (min-width: 1440px) {
  .content {
    padding: 0 40px;
  }
  .main-content {
    padding: 150px 40px;
  }
  .step-title-content {
    padding: 40px 40px;
  }
  .step-content {
    grid-template-columns: 1fr 1fr;
  }
}

.content-title,
.step-title {
  font-family: EuclidCircularSemibold, sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  max-width: 100%;
}

.policy-main-title {
  font-family: EuclidCircularSemibold, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  max-width: 100%;
  text-align: center;
}

.step-title {
  margin: 40px 0;
}

.policy-title {
  font-family: EuclidCircularSemibold, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  max-width: 100%;
  margin: 24px 0 0;
}

.policy-subtitle {
  margin-bottom: 0;
}

ul, ol {
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .content-title,
  .step-title {
    font-size: 48px;
    
  }

  .step-title {
    margin: 24px 0;
  }
}

.content-description,
.step-description {
  font-size: 28px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 40px;
}

.video-container {
  margin-top: 48px;
  max-width: 1480px;
  min-height: 50vh;
  background-color: #101520;
  padding: 15px;
  border-radius: 15px;
}


@media screen and (min-width: 768px) {
  .video-container {
    margin-top: 56px;
  }
  #MainVideo {
    display: visible;
  }
  #MainVideoMobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .video-container {
    margin-top: 16px;
  }
}

.main-video {
  max-width: 100%;
}

@media screen and (min-width: 480px) {
  .main-video {
    max-width: 432px;
  }
}

@media screen and (max-width: 767px) {
  #MainVideo {
    display: none;
  }
  #MainVideoMobile {
    display: visible;
  }
}

@media screen and (min-width: 768px) {
  .main-video {
    max-width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .main-video {
    max-width: 1200px;
  }
}

@media screen and (min-width: 1480px) {
  .main-video {
    max-width: 1400px;
  }
}

.hidden {
  opacity: 0;
  transition: all 1s;
}

.hidden .column-image {
  filter: blur(5px);
  transform: translateX(100%);
  transition: all 1s;
}

.hidden .column-image-right {
  transform: translateX(100%);
}

.hidden .column-image-bottom {
  transform: translateY(100%);
}

.show {
  opacity: 1;
}

.show .column-image {
  filter: blur(0);
}

.show .column-image-right {
  transform: translateX(0%);
}

.show .column-image-bottom {
  transform: translateY(0%);
}

.column-image {
  display: grid;
  align-content: center;
}

.step-image {
  max-width: 600px;
  width: 100%;
  height: auto;
}

@media(prefers-reduced-motion) {
  .hidden, .hidden * {
    transition: none;
  }
}


.footer {
  --adaptive-mf-container-padding: 20px;
  --adaptive-mf-container-max-width: 2360px;
  color: #131722;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 var(--adaptive-mf-container-padding);
  padding-bottom: 46px;
  padding-top: 22px;
  border-top: 1px solid #f0f3fa;
}

#footerLogo {
  height: 100px;
  width: auto;
}

@media screen and (min-width: 768px) {
  .footer {
      padding-bottom: 22px;
      padding-top: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .footer {
      padding-bottom: 20px;
      padding-top: 46px;
      --adaptive-mf-container-padding: 32px;
  }
}

@media screen and (min-width: 1440px){
  .footer {
      --adaptive-mf-container-padding: 40px;
  }
}


.footer-row {
  --footer-row-columns-gap: 16px;
  --footer-width: 100vw;
  column-gap: var(--footer-row-columns-gap);
  display: grid;
  grid-template-columns: repeat(1,1fr);
  row-gap: 20px;
  width: 100%;
}

@media screen and (min-width: 768px){
  .footer-row {
      --footer-row-columns-gap: 52px;
      row-gap: 34px;
  }
}

@media screen and (min-width: 1024px){
  .footer-row {
      --footer-row-columns-gap: calc(var(--footer-width)/4 - 211px + var(--adaptive-mf-container-padding)*2/4);
      grid-template-columns: 196px 4fr;
      justify-content: space-between;
  }
}

@media screen and (min-width: 1440px){
  .footer-row {
      --footer-row-columns-gap: calc(var(--footer-width)/4 - 287.5px + var(--adaptive-mf-container-padding)*2/4);
      grid-template-columns: 230px 4fr;
  }
}

.footer-socials {
    display: grid;
    grid-column: 1/-1;
    padding-bottom: 12px;
    row-gap: 20px;
    width: auto;
}

@media screen and (min-width: 768px){
  .footer-socials {
      column-gap: 40px;
      grid-template-columns: repeat(2,1fr);
      margin-right: 6px;
  }
}

@media screen and (min-width: 1024px){
  .footer-socials {
      display: block;
      grid-column: 1/2;
      margin-right: 0;
      padding-bottom: 0;
      width: 196px;
  }
}

.footer-socials_logo {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-socials_title {
  margin-left: 10px;
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.footer-links {
  column-gap: var(--tv-footer-row-columns-gap);
  display: grid;
  grid-template-columns: repeat(1,1fr);
  row-gap: var(--tv-footer-row-columns-gap);
}

@media screen and (min-width: 480px) {
  .footer-links {
      grid-template-columns: repeat(auto-fit,minmax(320px,1fr));
  }
}

@media screen and (min-width: 768px){
  .footer-links {
      grid-template-columns: repeat(2,1fr);
  }
}

.footer-links_row-group {
  column-gap: var(--tv-footer-row-columns-gap);
  display: grid;
  grid-template-columns: repeat(2,1fr);
}

.footer-links_column {
  display: flex;
  flex-direction: column;
}


.footer-links-column_title {
  --ui-lib-typography-line-height: 28px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  font-weight: 700;
  line-height: var(--ui-lib-typography-line-height);
  margin-bottom: 12px;
  color: #000;
  text-decoration: none;
}

.footer-links-column_title:hover,
.footer-links-column_title:active,
.footer-links-column_title:focus {
  color: #999;
}

@media screen and (min-width: 768px){
  .footer-links-column_title {
      --ui-lib-typography-line-height: 24px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      font-weight: 700;
      line-height: var(--ui-lib-typography-line-height);
      line-height: 22px;
  }
}

@media screen and (min-width: 1024px){
  .footer-links-column_title {
      --ui-lib-typography-line-height: 22px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      font-weight: 700;
      line-height: var(--ui-lib-typography-line-height);
  }
}

.footer-links-column_title {
    clear: left;
    float: left;
}

.footer-links-column_list {
  display: grid;
  list-style: none;
  row-gap: 8px;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 1024px){
  .footer-links-column_list {
      margin-bottom: 10px;
  }
}

.footer-links-column_link {
  fill: #131722;
  --ui-lib-typography-line-height: 24px;
  border-radius: 4px;
  color: #131722;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--ui-lib-typography-line-height);
  line-height: 24px;
  margin: -2px -8px;
  padding: 2px 8px;
  text-decoration: none;
  outline: none;
  overflow: visible;
  position: relative;
  clear: left;
  float: left;
}

@media screen and (min-width: 768px){
  .footer-links-column_link {
      --ui-lib-typography-line-height: 21px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: var(--ui-lib-typography-line-height);
      line-height: 22px;
  }
}

@media screen and (min-width: 1024px){
  .footer-links-column_link {
      --ui-lib-typography-line-height: 21px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: var(--ui-lib-typography-line-height);
      line-height: 22px;
  }
}

.footer-links-column_link:hover,
.footer-links-column_link:active,
.footer-links-column_link:focus {
  fill: #131722;
  color: #131722;
  background-color: #e0e3eb;
  outline: 0;
}

.footer-copyright {
  border-top: 1px solid #f0f3fa;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 32px;
  padding-top: 32px;
  width: 100%;
}

@media screen and (min-width: 1024px){
  .footer-copyright {
      flex-wrap: nowrap;
      margin-top: 14px;
      padding-top: 24px;
  }
}

.footer-copyright_column {
  --ui-lib-typography-line-height: 21px;
  color: #6a6d78;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: var(--ui-lib-typography-line-height);
  margin-top: 24px;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 1024px){
  .footer-copyright_column {
      margin-top: 0;
      text-align: left;
  }
}

.btn {
  padding: 15px;
  border-radius: 4px;
  text-transform: uppercase;
  text-decoration: none;
  overflow:hidden;
  background: none;
  z-index: 1;
  cursor: pointer;
  transition:         0.08s ease-in;
  -o-transition:      0.08s ease-in;
  -ms-transition:     0.08s ease-in;
  -moz-transition:    0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
}

.btn-primary,
.main-container a.btn-primary {
  font-weight: 900;
  color: #426cba;
  border: 1px solid #426cba;
  font-weight: 900;
  font-size: 28px;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.main-container a.btn-primary:hover,
.main-container a.btn-primary:focus,
.main-container a.btn-primary:active {
  color: #fff;
  background-color: #426cba;
}